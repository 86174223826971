import { HttpClient } from '@angular/common/http';
import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { CONFIG } from 'app/config/config';
import { IUser } from 'app/core/models/model.model';
import { jwtDecode } from 'jwt-decode';
import { catchError, map, Subject, TimeoutError } from 'rxjs';
import { EAuthState, IJwtLoginResponse, IJwtToken } from './auth.model';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  #httpClient = inject(HttpClient);
  #router = inject(Router);
  #authTokenMetaData: IJwtToken;
  #user: IUser;
  loginErrorMessage = signal<string>(null);
  authState$ = new Subject<EAuthState>();

  get authTokenMetaData(): IJwtToken | undefined {
    if (this.#authTokenMetaData) {
      return this.#authTokenMetaData;
    }
    const metaData = localStorage.getItem(
      `${environment.appName}-TokenMetadata`
    );
    if (metaData) {
      this.#authTokenMetaData = JSON.parse(metaData);
      return this.#authTokenMetaData;
    }
    return undefined;
  }

  set authTokenMetaData(authTokenMetaData: IJwtToken) {
    if (!authTokenMetaData) {
      //  clearTimeout(this.refreshTokenHandler);
      localStorage.removeItem(`${environment.appName}-TokenMetadata`);
      this.#authTokenMetaData = undefined;
    } else {
      this.#authTokenMetaData = authTokenMetaData;
      localStorage.setItem(
        `${environment.appName}-TokenMetadata`,
        JSON.stringify(authTokenMetaData)
      );
      // this.activateRefreshToken();
    }
  }

  get user(): IUser {
    if (this.#user) {
      return this.#user;
    }
    const user = sessionStorage.getItem(`${environment.appName}-User`);
    if (user) {
      this.#user = JSON.parse(user);
      return this.user;
    }
    return undefined;
  }

  set user(user: IUser) {
    if (!user) {
      sessionStorage.removeItem(`${environment.appName}-User`);
      this.#user = undefined;
    } else {
      this.#user = user;
      sessionStorage.setItem(
        `${environment.appName}-User`,
        JSON.stringify(user)
      );
    }
  }

  handleAuthentication({
    identifier,
    password,
  }: {
    identifier: string;
    password: string;
  }) {
    this.loginErrorMessage.set(null);
    this.authState$.next(EAuthState.LOGIN_IN_PROGRESS);
    this.#httpClient
      .post<IJwtLoginResponse>(`${environment.apiUrl}/auth/local`, {
        identifier,
        password,
      })
      .subscribe({
        next: (response) => {
          this.signIn(response);
        },
        error: (response) => {
          let message = '';
          switch (response?.error?.error?.message) {
            case 'Invalid identifier or password':
              message = 'Email o password invalidi';
              break;
            default:
              message = 'Qualcosa è andato storto, riprova più tardi';
              break;
          }
          this.onLoginError(message);
        },
      });
  }

  handleRedirect() {
    this.#router.navigate([CONFIG.urls.HOME]);
  }

  signIn(loginResponse: IJwtLoginResponse) {
    this.authTokenMetaData = this.decodeToken(loginResponse.jwt);
    this.authState$.next(EAuthState.TOKEN_RETRIEVED);
  }

  decodeToken(token: string): IJwtToken | undefined {
    if (!token) {
      return undefined;
    }

    const decodedToken: any = jwtDecode(token);
    Object.assign(decodedToken, {
      token: token,
      expiresAt: decodedToken.exp * 1000,
      iat: decodedToken.iat * 1000,
      exp: decodedToken.exp * 1000,
    });

    return decodedToken as IJwtToken;
  }

  isTokenValid(): boolean {
    return !this.isTokenExpired();
  }

  async renewToken(): Promise<IJwtToken> {
    this.signIn(undefined);
    return Promise.reject();
  }

  isTokenExpired(): boolean {
    if (this.authTokenMetaData && this.authTokenMetaData.expiresAt) {
      return Date.now() > this.authTokenMetaData.expiresAt;
    }
    return true;
  }

  loginUserWithToken() {
    return this.#httpClient
      .get(`${environment.apiUrl}/users/me`, {
        params: {
          'populate[role]': 'true',
          'populate[agentAgency][populate][0]': 'group',
        },
      })
      .pipe(
        map((response: IUser) => {
          this.user = response;
          if (!response) {
            throw new Error('Empty user!');
          }
          this.authState$.next(EAuthState.LOGGED);
          return true;
        }),
        catchError((error) => {
          if (!(error instanceof TimeoutError)) {
            this.signOut();
          }
          throw error;
        })
      );
  }

  signOut() {
    this.clearAuthData();
    this.authState$.next(EAuthState.TO_BE_LOGGED);
    setTimeout(() => {
      location.href = CONFIG.urls.HOME;
    }, 350);
  }

  clearAuthData() {
    this.user = undefined;
    this.authTokenMetaData = undefined;
  }

  onLoginError(
    errorMessage = '',
    authState: EAuthState = EAuthState.LOGIN_ERROR
  ) {
    this.loginErrorMessage.set(errorMessage);
    this.authState$.next(authState);
    this.clearAuthData();
  }
}
