import { IUser } from 'app/core/models/model.model';

export interface IJwtToken {
  token: string;
  refreshToken?: string;
  expiresAt: number;
  expiresIn: number;
  userId?: number | string;
  username?: string;
  id?: number;
  iss: string;
  sub: string;
  aud: string;
  iat: number;
  exp: number;
  at_hash: string;
  nonce: string;
}

export interface IJwtLoginResponse {
  jwt: string;
  user?: IUser;
}

export enum EAuthState {
  TO_BE_LOGGED = 'TO_BE_LOGGED',
  LOGIN_IN_PROGRESS = 'LOGIN_IN_PROGRESS',
  TOKEN_RETRIEVED = 'TOKEN_RETRIEVED',
  LOGGED = 'LOGGED',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGIN_INFO = 'LOGIN_INFO',
  EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED',
  NOT_AUTHORISED = 'NOT_AUTHORISED',
}
