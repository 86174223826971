export const promptToDownloadBlob = (
  file: Blob,
  fileExtension?: string,
  fileName?: string
) => {
  const objectUrl = URL.createObjectURL(file);
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    const objectUrlParts = objectUrl.split('/');
    (window.navigator as any).msSaveOrOpenBlob(
      file,
      fileName
        ? fileName
        : `${objectUrlParts[objectUrlParts.length - 1]}.${fileExtension}`
    );
  } else {
    const a = document.createElement('a');
    a.target = '_blank';
    a.href = objectUrl;
    if (fileName && fileExtension) {
      a.download = `${fileName}.${fileExtension}`;
    } else if (fileName) {
      a.download = `${fileName}`;
    }
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
};
