import { AsyncPipe } from '@angular/common';
import { afterNextRender, Component, DestroyRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { CONFIG } from 'app/config/config';
import { EAuthState } from 'app/core/services/auth/auth.model';
import { AuthService } from 'app/core/services/auth/auth.service';
import { LoaderComponent } from 'app/shared/components/loader/loader.component';
import { filter, switchMap } from 'rxjs';
import { AlertComponent } from '../../../shared/components/alert/alert.component';

@Component({
  selector: 'casa-doc-sign-in',
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    AlertComponent,
    AsyncPipe,
    LoaderComponent,
  ],
  templateUrl: './sign-in.component.html',
  styleUrl: './sign-in.component.scss',
})
export class SignInComponent {
  authService = inject(AuthService);
  eAuthState = EAuthState;
  private router = inject(Router);
  private destroyRef = inject(DestroyRef);

  form = new FormGroup({
    identifier: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    password: new FormControl('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  constructor() {
    afterNextRender(() => {
      if (this.authService.isTokenValid()) {
        this.router.navigate([CONFIG.urls.HOME]);
      } else {
        this.authService.authState$
          .pipe(
            takeUntilDestroyed(this.destroyRef),
            filter(
              (authState: EAuthState) =>
                authState === EAuthState.TOKEN_RETRIEVED
            ),
            switchMap(() => this.authService.loginUserWithToken())
          )
          .subscribe(() => {
            this.authService.handleRedirect();
          });
      }
    });
  }

  onLogin() {
    if (this.form.valid) {
      this.authService.handleAuthentication(this.form.getRawValue());
    }
  }
}
