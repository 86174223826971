import imageCompression, {
  Options as ImageCompressionOptions,
} from 'browser-image-compression';
import { WatermarkCanvas } from './canvas';
import { calculateCoordinates } from './helpers/calculate-coordinates';
import {
  WatermarkBaseCoordinates,
  WatermarkCoordinates,
  WatermarkImage,
  WatermarkText,
  Watermarked,
} from './types/watermark';
export class Watermark {
  #image: Watermarked;
  #original: File;
  #watermarkCanvas: WatermarkCanvas;
  #imageCompressionOptions: ImageCompressionOptions;

  private constructor(image: File, watermarkCanvas: WatermarkCanvas) {
    this.#original = image;
    this.#image = Object.assign(image, {
      previewUrl: '',
      width: watermarkCanvas.canvas.width,
      height: watermarkCanvas.canvas.height,
    });
    this.#watermarkCanvas = watermarkCanvas;
  }

  static async load(image: File) {
    const promise = new Promise((resolve, reject) => {
      const imageElement = document.createElement('img');

      imageElement.src = URL.createObjectURL(image);

      imageElement.onload = async () => {
        const watermarkCanvas = WatermarkCanvas.create({
          width: imageElement.width,
          height: imageElement.height,
        });

        watermarkCanvas.drawOriginal(imageElement, 0, 0);
        return resolve(watermarkCanvas);
      };

      imageElement.onerror = () =>
        reject(new Error(`${image.name} has invalid image format.`));
    }) as Promise<WatermarkCanvas>;
    const watermarkCanvas = await promise;

    return new Watermark(image, watermarkCanvas);
  }

  static async loadBlob(image: Blob) {
    await this.load(new File([image], '', { type: image.type }));
  }

  private getCoordinates(
    isPortrait: boolean,
    coordinates: WatermarkCoordinates
  ) {
    if (isPortrait && coordinates.portrait) {
      return coordinates.portrait;
    }

    if (!isPortrait && coordinates.landscape) {
      return coordinates.landscape;
    }

    return coordinates as WatermarkBaseCoordinates;
  }

  async applyImage({
    path,
    coordinates,
    position,
    scale,
    offset,
    offsetScale,
  }: WatermarkImage) {
    const result = await fetch(path);
    const blob = await result.blob();
    const watermark = await createImageBitmap(blob);

    const isPortrait = this.#image.height > this.#image.width;

    let _coordinates = { x: 0, y: 0 };

    if (coordinates && !position) {
      _coordinates = this.getCoordinates(isPortrait, coordinates);
    }

    let watermarkWidth = watermark.width;
    let watermarkHeight = watermark.height;
    if (scale) {
      watermarkWidth = this.#image.width * scale;
      watermarkHeight = watermark.height * (watermarkWidth / watermark.width);
    }

    if (position) {
      _coordinates = calculateCoordinates({
        position,
        imageDimensions: {
          height: this.#image.height,
          width: this.#image.width,
        },
        watermarkDimensions: {
          height: watermarkHeight,
          width: watermarkWidth,
        },
        watermarkIsText: false,
        offset,
        offsetScale,
      });
    }

    this.#watermarkCanvas.drawWatermarkImage(
      watermark,
      _coordinates.x,
      _coordinates.y,
      watermarkWidth,
      watermarkHeight
    );

    return this;
  }

  async applyText({
    text,
    coordinates,
    style,
    position,
    offset,
    offsetScale,
  }: WatermarkText) {
    const isPortrait = this.#image.height > this.#image.width;

    let _coordinates = { x: 0, y: 0 };

    if (coordinates && !position) {
      _coordinates = this.getCoordinates(isPortrait, coordinates);
    }

    this.#watermarkCanvas.applyTextStyles(style);

    const metrics = this.#watermarkCanvas.context.measureText(text);
    const watermarkHeight =
      metrics.fontBoundingBoxAscent - metrics.fontBoundingBoxDescent;
    const watermarkWidth = metrics.width;

    if (position) {
      _coordinates = calculateCoordinates({
        position,
        imageDimensions: {
          height: this.#image.height,
          width: this.#image.width,
        },
        watermarkDimensions: {
          height: watermarkHeight,
          width: watermarkWidth,
        },
        watermarkIsText: true,
        offset,
        offsetScale,
      });
    }

    // adding a watermark text
    this.#watermarkCanvas.drawWatermarkText(
      text,
      _coordinates.x,
      _coordinates.y
    );

    return this;
  }

  public async enhanceImage({
    brightnessFactor = 1,
    contrastFactor = 1,
    saturationFactor = 1,
  }: {
    brightnessFactor: number;
    contrastFactor: number;
    saturationFactor: number;
  }) {
    this.#watermarkCanvas.enhance({
      brightnessFactor,
      contrastFactor,
      saturationFactor,
    });

    return this;
  }

  async getResult(imageCompressionOptions?: ImageCompressionOptions) {
    const promise = new Promise((resolve) => {
      this.#watermarkCanvas.result((blob) => resolve(blob));
    }) as Promise<Blob>;

    const blobImage = await promise;
    if (!blobImage) {
      throw new Error(`an error has occured on ${this.#original.name}`);
    }

    let file = new File([blobImage], this.#original.name, {
      type: blobImage.type,
    });
    if (imageCompressionOptions) {
      file = await imageCompression(file, imageCompressionOptions);
    }
    return file;
  }
}
