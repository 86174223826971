import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { CONFIG } from 'app/config/config';
import { catchError, from, map, of, switchMap } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';

export const authGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  const platformId = inject(PLATFORM_ID);
  if (!isPlatformBrowser(platformId)) {
    return false;
  }
  // if (authService.user && authService.isTokenValid()) {
  //   return true;
  // } else

  // NOTE: force to get the user info so all the data is up to date
  if (authService.isTokenValid()) {
    return authService.loginUserWithToken().pipe(
      catchError(() => {
        window.location.href = CONFIG.urls.SIGN_IN;
        return of(false);
      })
    );
  } else {
    // Try to renew the token
    return from(authService.renewToken()).pipe(
      switchMap(() => authService.loginUserWithToken().pipe(map(() => true))),
      catchError(() => {
        window.location.href = CONFIG.urls.SIGN_IN;
        return of(false);
      })
    );
  }
};
