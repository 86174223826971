import { Routes } from '@angular/router';
import { NotAuthorizedComponent } from './core/components/not-authorized/not-authorized.component';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { SignInComponent } from './core/components/sign-in/sign-in.component';
import { authGuard } from './core/guard/auth.guard';
import { roleGuard } from './core/guard/role.guard';
import { ERoleType } from './core/models/model.model';
import { WatermarkComponent } from './watermark/watermark/watermark.component';

export const routes: Routes = [
  {
    path: '',
    canActivate: [authGuard],
    children: [
      {
        path: '',
        component: WatermarkComponent,
        canActivate: [roleGuard],
        data: {
          role: ERoleType.AGENT,
        },
      },
    ],
  },
  {
    path: 'sign-in',
    component: SignInComponent,
  },
  {
    path: 'not-authorized',
    component: NotAuthorizedComponent,
  },

  {
    path: '**',
    component: NotFoundComponent,
  },
];
